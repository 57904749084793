import React from 'react';
import _, {isEmpty } from 'lodash';
import { getCurrentTab, getTabLists, collectMenuTabs } from "../../helper"
import { withSubtheme } from '../../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlobalLayout from "../../GlobalLayout";
import Tabs from '../../tabs';
import MenuListTabs from '../../iconMenuList/menuListIconsTabs';
import SingleProperty from '../../property/singleProperty';
// import ContactModule from '../../contactModule';
// import SimpleContactModule from '../../contactModule/simpleContact';
import PendingItems from '../../pendingItems';
import PageTitle from '../../pageTitle';
// import Activities from '../../activities';
// import FeedbackProgress from '../../feedbackProgress';
import Container from '@mui/material/Container';
import FloatingContainer from '../../floatingContainer';
import ContactBtn from '../../property/components/contactBtn';
import _properties from '../../../../sample/properties.yaml';
import { MyaccountStoreProvider, PropertyActionStoreProvider, useMyaccountState, useAuthState } from "../../../../services";
// import Actions from '../../actions';
import PendingActions from '../../pendingActions/propertyDetails';
import RecentActivites from '../../recentActivities/propertyDetails';
// import OnlyActions from '../../pendingActions/propertyDetails';
// import ActionsWithLayout from '../../pendingActions/withGlobalLayout';
import NoActions from "../../progress/NoActions";
// import Calendar from '../../calendar';
// import { /* calendarDate, */getMonth } from '../../helper/dateformat';
// import { MYACCOUNT_INBOX } from "../../../../constants/urls";
import DocumentContent from "../../pages/my-property/common/document";
import ContactModuleComp from "../../pages/my-property/common/contactModule";
import AppointmentsContent from "../../pages/my-property/common/appointment/calendar";
import FeedbackContent from "../../pages/my-property/common/feedback";
import PaymentsContent from "../../pages/my-property/common/transaction";
import OfferContent from "../../pages/my-property/common/offer";
import StatsContent from "../../pages/my-property/common/stats";
import PerformanceContent from "../../pages/my-property/common/performance";
import MyPropertySkeleton from "./myPropertySkeleton"
import defaults from './defaults';

const OverviewContent = (props) => {
    const isMobile = props.mobile;
    if (isMobile) {
        return(
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TabsMobileComp {...props} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ActivityTabsComp
                                theme={props.theme}
                                property={props.property}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PendingItemsComp
                                property={props.property}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StatsContent
                                title="test"
                                property={props.property}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                        negInfo={props.property?.negotiator_info}
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.negotiator_info}
                        moduleTitle="Contact your agent"
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.manager_negotiator_info}
                        moduleTitle="Property manager"
                    />
                </Grid>
            </Grid>
        )
    }
}

// Calendar Action Container wrap for enable loading
// const ActionDataContainer = ({props, state, date}) => {
//
//     const data = state?.propertyAppointments || []
//     const monthName = getMonth(date);
//
//     if (state.loading_actions) {
//         return <CircularProgress />
//     }
//
//     if (!state.loading_actions && !data.length) {
//         return (
//             <NoActions
//                 title="There are no pending actions"
//                 message="At the moment all the actions are closed..."
//                 icon={PendingActionsIcon}
//             />
//         )
//     }
//
//     return (
//         <Actions
//             title={monthName}
//             {...props}
//             data={props?.data ? props.data : data}
//         />
//     )
// }

const PendingItemsComp = (props) => {
    const progressionData = props.property?.sales_progression || [];
    return (
        <PendingItems
            data={progressionData}
            title="Sales Progress"
        />
    )
}

const FloatingContainerComp = (props) => {
    let showContactBtn = false;
    if (isEmpty(props.negInfo)) {
        showContactBtn = true;
    }

    if (!showContactBtn)
        return <></>

    return (
        <FloatingContainer>
            <ContactBtn btnLabel="Contact Us" {...props} />
        </FloatingContainer>
    )
}
const SinglePropertyComp = (props) => {

    let offerText = '';//'No offers received';
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`;
    }

    let property = _properties[0]; // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText
        }
    }

    return(
        <>
            <SingleProperty
                theme={props.theme}
                properties={[property]}
                altClassName={`persona-details`}
            />
        </>
    )
}

const mainTabData = (props) => {

    return (
        {
            "overview": {
                "iconName": "overviewTabIcon",
                "id": "overview",
                "name": "Overview",
                "subtitle": "Lorem ipsum dolor sit amet",
                "content": <OverviewContent {...props} />,
            },
            "appointments":{
                "id": "appointments",
                "iconName": "appointmentsIcon",
                "name": "Appointments",
                "subtitle": "Lorem ipsum dolor sit amet",
                "content": <AppointmentsContent {...props} />,
            },
            "feedback": {
                "id": "feedback",
                "iconName": "emailIcon",
                "name": "Feedback",
                "subtitle": "Lorem ipsum dolor sit amet",
                "content": <FeedbackContent {...props} />,
            },
            "offers": {
                "id": "offers",
                "iconName": "offerIcon",
                "name": "Offers",
                "content": <OfferContent {...props} />,
            },
            "documents": {
                "id": "documents",
                "iconName": "documentsIcon",
                "name": "Documents",
                "content": <DocumentContent {...props} />,
            },
            "transactions": {
                "id": "transactions",
                "iconName": "transactionsIcon",
                "name": "Transaction",
                "content": <PaymentsContent {...props} />,
            },
            "performance": {
                "id": "performance",
                "iconName": "marketingPerformance",
                "name": "Performance",
                "content": <PerformanceContent {...props} />,
            }
        }
    )
}

const MainTabsComp = (props) => {

    const { services: authServices } = useAuthState()
    const property = props?.property
    const pageTitle = property?.property_detail?.display_address;

    // tabItems Can be Array or Objects
    const _tabItems     = ['overview', 'appointments', 'feedback', 'offers', 'documents']
    const _defaultItem  = 'overview';
    const tabItems      = props?.tab?.items || _tabItems;
    const defaultItem   = props?.tab?.default || _defaultItem;
    const currentTab    = getCurrentTab(defaultItem, tabItems)
    const availableTabs = mainTabData(props) || {}
    const data          = getTabLists(availableTabs, tabItems)

    const [value, setValue] = React.useState(currentTab);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const pageRightDrawer = <RightDrawerContent
               props={{
                   property: property,
                   manageValue: value,
                   manageHandleChange: handleChange,
                   tabItems,
                   addUrl: true,
                   data: data,
               }}
           />
        authServices.updateState({
            pageTitle: pageTitle,
            pageRightDrawer: pageRightDrawer,
            pageLeftIcon: true,
        });
    }, [pageTitle, value]) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Tabs
            data={data}
            manageValue={value}
            addUrl={true}
            tabItems={tabItems}
            altTabClassName={`pd-main`}
        />
    )
}

const ActivityTabsComp = (props) => {
    const dataActivity = [
        {
            "id": "tab1",
            "name": "Pending Actions",
            "content": <PendingActions property={props?.property} />,
        },
        {
            "id": "tab2",
            "name": "Recent Activity",
            "content": <RecentActivites property={props?.property} />,
        }
    ]
    return(
        <Tabs
            data={dataActivity}
            altTabClassName={`pd-second`}
        />
    )
}

const mobileTabData = (props) => {
    return(
        [
            {
                "id": "tab1",
                "name": "Overview",
                "content":
                <>
                    <ContactModuleComp
                        simpleContact={true}
                        data={{...props.property?.negotiator_info, helpTextLink: "Get in touch", helpText: "Need help? "}}
                    />
                    <PropertyActionStoreProvider>
                        <PendingActions
                            property={props?.property}
                            title="Pending Actions"
                            componentLayout={true}
                            // moreLabel="View all"
                            // moreLinkUrl={MYACCOUNT_INBOX}
                            // noActionTitle="There are no pending actions"
                            // noActionMessage="At the moment all the actions are closed..."
                        />
                    </PropertyActionStoreProvider>
                    <PendingItemsComp
                        property={props.property}
                    />
                    {/*
                    <MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />
                    */}
                    <FloatingContainerComp
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                    />
                </>,
            },
            {
                "id": "tab2",
                "name": "Activity",
                "content": <RecentActivites property={props?.property} />,
            },
        ]
    )
}
const TabsMobileComp = (props) => {
    return(
        <Tabs
            data={mobileTabData(props)}
            fullWidthTabs={true}
            altTabClassName={`pd-second`}
        />
    )
}


const MyAccountPropertySellerHeaderProperty = withSubtheme((props) => {
    const { className, properties, property } = props;
    return(
        <div className={className}>
            <SinglePropertyComp
                properties={properties}
                property={property?.property_detail}
            />
        </div>
    )
}, 'myPropertyPageSingleHeaderProperty')

const MenuListTabsComp = (props) => {
    const origData = props?.data || mainTabData(props);
    // Create a new array with only the id and name properties, we don't need the rest for the menus
    const newData = collectMenuTabs(origData)

    return(
        <MenuListTabs
            {...props}
            title={props.title && props.title}
            data={newData}
        />
    )
}

const RightDrawerContent = ({props}) => {
    return(
        <MenuListTabsComp
            {...props}
        />
    )
}

const MyAccountPropertySellerInit = React.memo(({props}) => {
    const { state } = useMyaccountState();
    // const { services: authServices } = useAuthState()
    const { theme } = props;
    const properties = _properties ?? theme.getProp('subthemes.result.sampleData');
    const mobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const property = state?.property || {}
    const property_detail = property?.property_detail || {}
    const pageTitle = property_detail?.display_address;


    if (!state.loading && _.isEmpty(property_detail)) {
        return (
            <NoActions
                title="Property not found"
                message="At the moment this property not found"
                icon="propertyNotFound"
            />
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <PageTitle theme={theme}>{pageTitle}</PageTitle>
            </Grid>
            {mobile &&
                <Grid item xs={12}>
                    <MyAccountPropertySellerHeaderProperty
                        theme={theme}
                        properties={properties}
                        property={property}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <MainTabsComp
                    {...props}
                    mobile={mobile}
                    theme={theme}
                    properties={properties}
                    // manageValue={value}
                    // manageHandleChange={handleChange}
                    property={property}
                />
            </Grid>
        </Grid>
    );
})

const WrapComp = React.memo((props) => {

    const {
        theme,
        propertyid,
    } = props;

    const { services, state } = useMyaccountState();

    // fetch the property datas and do the process then
    React.useEffect(() => {
        services.getProperty({id:propertyid})
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return <MyPropertySkeleton tabsCount={6} />
    }

    return (
        <Container maxWidth="xl">
            <MyAccountPropertySellerInit props={{...props, theme:theme}} />
        </Container>
    )
})

const MyAccountPropertySeller = withSubtheme((props) => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <PropertyActionStoreProvider>
                    <WrapComp {...props} />
                </PropertyActionStoreProvider>
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}, 'myPropertyPageSeller', defaults)

export default React.memo(MyAccountPropertySeller);
