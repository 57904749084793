import React from 'react';
import _, { isEmpty } from 'lodash';
import { getCurrentTab, getTabLists, collectMenuTabs } from "../../helper"
import { withSubtheme } from '../../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlobalLayout from "../../GlobalLayout";
import Tabs from '../../tabs';
import MenuListTabs from '../../iconMenuList/menuListIconsTabs';
import SingleProperty from '../../property/singleProperty';
import FloatingContainer from '../../floatingContainer';
import ContactBtn from '../../property/components/contactBtn';
// import ReportFaultBtn from '../../property/components/reportFaultBtn';
// import ContactModule from '../../contactModule';
// import SimpleContactModule from '../../contactModule/simpleContact';
import PageTitle from '../../pageTitle';
// import Activities from '../../activities';
import Container from '@mui/material/Container';
import _properties from '../../../../sample/properties.yaml';
import {  MyaccountStoreProvider, PropertyActionStoreProvider, useMyaccountState, useAuthState } from "../../../../services";
import PendingActions from '../../pendingActions/propertyDetails';
import RecentActivites from '../../recentActivities/propertyDetails';
// import OnlyActions from '../../pendingActions/propertyDetails';
import ActionsWithLayout from '../../pendingActions/withGlobalLayout';
import NoActions from "../../progress/NoActions";
// import { canShowNewDocumentBtn } from '../../property/components/newDocument/utils';
import FeedbackContent from "../../pages/my-property/common/feedback";
import AppointmentsContent from "../../pages/my-property/common/appointment/calendar";
import TenancyContent from "../../pages/my-property/common/tenancy";
import PaymentsContent from "../../pages/my-property/common/transaction";
// import TaskContent from "../../pages/my-property/common/task";
import FaultIssuesContent from "../../pages/my-property/common/faultsandissues";
import DocumentContent from "../../pages/my-property/common/document";
import TenancyOverview from "../../pages/my-property/common/tenancyOverview";
import ContactModuleComp from "../../pages/my-property/common/contactModule";
import PerformanceContent from "../../pages/my-property/common/performance";
import MyPropertySkeleton from "./myPropertySkeleton"
import defaults from './defaults';

// --------------------------------------------------------------------------
// SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT

const SinglePropertyComp = (props) => {

    let offerText = '';
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`;
    }

    let property = _properties[0]; // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText
        }
    }

    return(
        <>
            <SingleProperty
                properties={[property]}
                altClassName={`persona-details`}
            />
        </>
    )
}

const FloatingContainerComp = ({property, negInfo}) => {

    const params = {
        property: property?.property_detail,
        is_owner: property?.is_owner,
        current_tenancy_id: property?.current_tenancy_id,
        owner_type: property?.owner_type,
        tenant_details: property?.current_tenancy?.tenant_details,
    }

    let showContactBtn = false;
    if (isEmpty(negInfo)) {
        showContactBtn = true;
    }

    if (!showContactBtn)
        return <></>

    return (
        <FloatingContainer>
            <ContactBtn btnLabel="Contact Us" {...params} />
        </FloatingContainer>
    )
}

// const PendingActionsComp = ({data, ...rest}) => {
//     return (
//         <ActionsWithLayout
//             title="Pending Actions"
//             moreLabel="View all"
//             noActionTitle="There are no pending actions"
//             noActionMessage="At the moment all the actions are closed..."
//             data={(rest?.property?.pendingActions || [])}
//         />
//     )
//     // const noRecord = (!data.length);
//     // return (
//     //     <>
//     //         {noRecord && (
//     //             <NoActions
//     //                 title="There are no pending actions"
//     //                 message="At the moment all the actions are closed..."
//     //                 icon={PendingActionsIcon}
//     //             />
//     //         )}
//     //         {!noRecord && (
//     //             <Actions {...rest} data={data} />
//     //         )}
//     //     </>
//     // )
// }

const InspectionsComp = (props) => {
    let data = props?.inspections || [];

    if (!data.length)
        return <></>

    return (
        <ActionsWithLayout
            title="Inspections"
            // moreLabel="View all"
            noActionTitle="There are no inspections"
            noActionMessage="At the moment all the inspections are closed..."
            data={data}
            role={`owner`}
        />
    )
}

// const RecentActivites = ({data, ...rest}) => {
//     const noRecord = (!data.length);
//     return (
//         <>
//             {noRecord && (
//                 <NoActions
//                     title="There are no recent activities"
//                     icon={InfoIcon}
//                 />
//             )}
//             {!noRecord && (
//                 <Activities {...rest} data={data} />
//             )}
//         </>
//     )
// }

/*
const CurrentTenancyComp = (props) => {
    const tenancyData = props.property?.current_tenancy || [];
    const utilities = tenancyData?.utilities || []
    const currentData = [
        {
            "id": "1",
            "title": "Tenant:",
            "value": tenancyData?.applicant_name
        },
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        },
        {
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        }
    ]
    return (
        <>
            <CurrentTenancy
                title="Current Tenancy"
                data={currentData}
            />
            <UtilitiesComp data={utilities} />
        </>
    )
}
const ArrangingTenancyComp = (props) => {
    const tenancyData = props.property?.arranging_tenancy || [];
    const utilities = tenancyData?.utilities || []
    const check_in = tenancyData?.check_in || []
    const currentData = [
        {
            "id": "1",
            "title": "Tenant:",
            "value": tenancyData?.applicant_name
        },
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        },
        {
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        }
    ]
    return (
        <>
            <CurrentTenancy
                title="Arranging Tenancy"
                data={currentData}
            />
            <UtilitiesComp data={utilities} />
            <CheckInComp
                data={check_in}
            />
        </>
    )
}
const CompletedTenancyComp = (props) => {
    const tenancyData = props.property?.completed_tenancy || [];
    const utilities = tenancyData?.utilities || []
    const check_out = tenancyData?.check_out || []
    const currentData = [
        {
            "id": "1",
            "title": "Tenant:",
            "value": tenancyData?.applicant_name
        },
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        },
        {
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        }
    ]
    return (
        <>
            <CurrentTenancy
                title="Completed Tenancy"
                data={currentData}
            />
            <UtilitiesComp data={utilities} />
            <CheckOutComp
                data={check_out}
            />
        </>
    )
}

}*/

// END SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// TAB COMPONENT CONTENT GRID
const OverviewContent = (props) => {
    const isMobile = props.mobile;
    const landlordConfig = props?.landlord;
    if (isMobile) {
        return(
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TabsMobileComp {...props} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {(landlordConfig?.showPendingAction) && (
                                <ActivityTabsComp
                                    theme={props.theme}
                                    property={props.property}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <InspectionsComp {...props.property} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenancyOverview property={props.property} />
                            {/*
                            <CurrentTenancyComp property={props.property} />
                            <ArrangingTenancyComp property={props.property} />
                            <CompletedTenancyComp property={props.property} />
                            */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        property={props.property}
                        negInfo={props.property?.negotiator_info}
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.negotiator_info}
                        moduleTitle="Contact your agent"
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.manager_negotiator_info}
                        moduleTitle="Property manager"
                    />
                </Grid>
            </Grid>
        )
    }
}

// // Calendar Action Container wrap for enable loading
// const ActionDataContainer = ({props, state, date}) => {
//
//     const data = state?.propertyAppointments || []
//     const monthName = getMonth(date);
//
//     if (state.loading_actions) {
//         return <CircularProgress />
//     }
//
//     if (!state.loading_actions && !data.length) {
//         return (
//             <NoActions
//                 title="There are no pending actions"
//                 message="At the moment all the actions are closed..."
//                 icon={PendingActionsIcon}
//             />
//         )
//     }
//
//     return (
//         <Actions
//             title={monthName}
//             {...props}
//             data={props?.data ? props.data : data}
//         />
//     )
// }

// const AppointmentsContent = (props) => {
//
//     const { state, services } = usePropertyActionState();
//     const data = state?.propertyAppointments || [];
//     const noRecord = (!state.loading && !data.length);
//     const [date, setDate] = React.useState(new Date());
//
//     const getAvailableDate = React.useCallback(() => {
//         // collect available dates
//         let availableDate = {};
//         let availableDateFinal = {}
//
//         // For testing data purposes using propertyAppointments
//         if (data.length) {
//             if (state.propertyAppointments) {
//                 Object.entries(state.propertyAppointments).map(([key, value]) => {
//                     if (typeof value.value === 'string') {
//                         const calDate = new Date(value.value);
//                         const calDateMonth = calDate.getMonth();
//                         const calDateDay = calDate.getDate();
//                         if (availableDate.hasOwnProperty(calDateMonth)) {
//                             availableDate[calDateMonth].push(calDateDay);
//                         } else {
//                             availableDate[calDateMonth] = [/*new Date().getDate(),*/ calDateDay];
//                         }
//                     }
//                     return availableDate;
//                 })
//
//                 // do sorting
//                 Object.entries(availableDate).map(([key, value]) => {
//                     availableDateFinal[key] = value.sort();
//                     return availableDateFinal;
//                 })
//             }
//         }
//         return availableDateFinal;
//     }, [date, state?.propertyAppointments]) // eslint-disable-line react-hooks/exhaustive-deps
//
//     React.useEffect(() => {
//         services.getPropertyAppointments({property_id:props.property.property_detail.crm_id/*, actionDate: calendarDate(date).toString()*/});
//     }, [date]) // eslint-disable-line react-hooks/exhaustive-deps
//
//     if (state.loading) {
//         return <CircularProgress />
//     }
//
//     return (
//         <>
//             {noRecord && (
//                 <NoActions
//                     title="There are no appointments"
//                     message="At the moment all the appointments closed..."
//                     icon={VisibilityIcon}
//                 />
//             )}
//             {!noRecord && (
//                 <Grid container spacing={1}>
//                     <Grid item xs={12} lg={6} xl={8}>
//                         <ActionDataContainer
//                             date={date}
//                             props={props}
//                             state={state}
//                         />
//                     </Grid>
//                     <Grid item xs={12} lg={6} xl={4}>
//                         <Calendar date={date} setDate={setDate} getAvailableDate={getAvailableDate} />
//                     </Grid>
//                 </Grid>
//             )}
//         </>
//     )
//
// }
//

// END TAB COMPONENT CONTENT GRID
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
const mainTabData = (props) => {

    // const appointments = /*props.property?.appointments || */[];
    // const tenancies = props.property?.tenancies || [];

    let tabItems = {
        "overview": {
            "id": "overview",
            "iconName": "overviewTabIcon",
            "name": "Overview",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <OverviewContent {...props} />,
        },
        "tenancy": {
            "id": "offers",
            "iconName": "tenancyIcon",
            "name": "Offers",
            "content": <TenancyContent {...props} />,
        },
        "fault-and-issues": {
            "id": "fault-and-issues",
            "iconName": "faultsIcon",
            "name": "Fault & Issues",
            "content": <FaultIssuesContent {...props} />,
        },
        "documents": {
            "id": "documents",
            "iconName": "documentsIcon",
            "name": "Documents",
            "content": <DocumentContent {...props} type="lettings" role="landlord" />,
        },
        "appointments": {
            "id": "appointments",
            "iconName": "appointmentsIcon",
            "name": "Appointments",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <AppointmentsContent {...props} />,
            // "content": <FaultIssuesContent {...props} />,
            // <UtilitiesComp />
            //     <InspectionsComp />
        },
        "feedback": {
            "id": "feedback",
            "iconName": "emailIcon",
            "name": "Feedback",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <FeedbackContent {...props} />,
        },
        "transactions": {
            "id": "transactions",
            "iconName": "transactionsIcon",
            "name": "Transaction",
            "content": <PaymentsContent {...props} />,
        },
        //"tasks": {
        //     "id": "tasks,
        //     "iconName": "Tasks",
        //     "name": "Tasks",
        //     "content": <TaskContent {...props} />,
        // }
        "performance": {
            "id": "performance",
            "iconName": "marketingPerformance",
            "name": "Performance",
            // "subtitle": "Lorem ipsum dolor sit amet",
            "content": <PerformanceContent {...props} />,
        }
    }
    return tabItems;
}
const MainTabsComp = (props) => {

    const { services: authServices } = useAuthState()
    const property = props?.property
    const pageTitle = property?.property_detail?.display_address;

    // tabItems Can be Array or Objects
    const _tabItems     = ['overview', 'tenancy', 'fault-and-issues', 'documents', 'appointments', 'feedback', 'transactions'];
    const _defaultItem  = 'overview';
    const tabItems      = props?.tab?.items || _tabItems;
    const defaultItem   = props?.tab?.default || _defaultItem;
    const currentTab    = getCurrentTab(defaultItem, tabItems)
    const availableTabs = mainTabData(props) || {}
    const data          = getTabLists(availableTabs, tabItems)

    const [value, setValue] = React.useState(currentTab);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const pageRightDrawer = <RightDrawerContent
               props={{property: property, manageValue:value, manageHandleChange:handleChange,tabItems:tabItems, addUrl:true, data:data}}
           />
        authServices.updateState({pageTitle: pageTitle, pageRightDrawer:pageRightDrawer, pageLeftIcon: true});
    }, [pageTitle, value]) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Tabs
            data={data}
            manageValue={value}
            tabItems={tabItems}
            addUrl={true}
            altTabClassName={`pd-main`}
        />
    )
}

const ActivityTabsComp = (props) => {
    const dataActivity = [
        {
            "id": "tab1",
            "name": "Pending Actions",
            "content": <PendingActions property={props?.property} />,
        },
        {
            "id": "tab2",
            "name": "Recent Activity",
            "content": <RecentActivites property={props?.property} />,
        }
    ]
    return(
        <Tabs
            data={dataActivity}
            altTabClassName={`pd-second`}
        />
    )
}

const mobileTabData = (props) => {
    return (
        [
            {
                "id": "tab1",
                "name": "Overview",
                "content":
                <>
                    <ContactModuleComp
                        simpleContact={true}
                        data={{...props.property?.negotiator_info, helpTextLink: "Get in touch", helpText: "Need help? "}}
                    />
                    <PropertyActionStoreProvider>
                        <PendingActions
                            property={props?.property}
                            title="Pending Actions"
                            componentLayout={true}
                            // moreLabel="View all"
                            // moreLinkUrl={MYACCOUNT_INBOX}
                            // noActionTitle="There are no pending actions"
                            // noActionMessage="At the moment all the actions are closed..."
                        />
                    </PropertyActionStoreProvider>
                    <InspectionsComp {...props.property} />
                    <TenancyOverview property={props.property} />
                    <FloatingContainerComp
                        property={props.property}
                    />
                    {/*
                    <CurrentTenancyComp property={props.property} />
                    <ArrangingTenancyComp property={props.property} />
                    <CompletedTenancyComp property={props.property} />
                    */}
                    {/*
                    <MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />
                    */}
                </>
            },
            {
                "id": "tab2",
                "name": "Activity",
                "content": <RecentActivites property={props?.property} />,
            },
        ]
    )
}
const TabsMobileComp = (props) => {
    return(
        <Tabs
            data={mobileTabData(props)}
            fullWidthTabs={true}
            altTabClassName={`pd-second`}
        />
    )
}

// const faultTabData = (props) => {
//     return (
//         [
//             {
//                 "id": "tab1",
//                 "name": "Open",
//                 "content": <Activities />
//             },
//             {
//                 "id": "tab2",
//                 "name": "Closed",
//                 "content": <RecentActivites {...props} data={(props?.property?.activity || [])} />
//             },
//         ]
//     )
// }
// const TabsFaultComp = (props) => {
//     return(
//         <Tabs
//             data={faultTabData(props)}
//             fullWidthTabs={true}
//         />
//     )
// }
// END MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
// --------------------------------------------------------------------------


const MyAccountPropertySellerHeaderProperty = withSubtheme((props) => {
    const { className, properties, property } = props;

    return(
        <div className={className}>
            <SinglePropertyComp
                properties={properties}
                property={property?.property_detail}
            />
        </div>
    )
}, 'myPropertyPageSingleHeaderProperty')

const MenuListTabsComp = (props) => {
    const origData = props?.data || mainTabData(props);
    // Create a new array with only the id and name properties, we don't need the rest for the menus
    const newData = collectMenuTabs(origData)

    return(
        <MenuListTabs
            {...props}
            title={props.title && props.title}
            data={newData}
        />
    )
}

const RightDrawerContent = ({props}) => {
    return(
        <MenuListTabsComp
            {...props}
        />
    )
}

const MyAccountPropertyLandlordInit = React.memo(({props}) => {

    const { state } = useMyaccountState();
    const {theme} = props;
    const properties = _properties ?? theme.getProp('subthemes.result.sampleData');
    const mobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const property = state?.property || {}
    const property_detail = property?.property_detail || {}
    const pageTitle = property_detail?.display_address;

    if (state.loading) {
        return <MyPropertySkeleton tabsCount={7} />
    }

    if (!state.loading && _.isEmpty(property_detail)) {
        return (
            <NoActions
                title="Property not found"
                message="At the moment this property not found"
                icon="propertyNotFound"
            />
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <PageTitle theme={theme}>{pageTitle}</PageTitle>
            </Grid>
            {mobile &&
                <Grid item xs={12}>
                    <MyAccountPropertySellerHeaderProperty
                        theme={theme}
                        properties={properties}
                        property={property}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <MainTabsComp
                    {...props}
                    mobile={mobile}
                    theme={theme}
                    properties={properties}
                    // manageValue={value}
                    // manageHandleChange={handleChange}
                    property={property}
                />
            </Grid>
        </Grid>
    );
})

const WrapComp = React.memo((props) => {

    const {
        theme,
        propertyid,
    } = props;

    const { services } = useMyaccountState();

    // fetch the property datas and do the process then
    React.useEffect(() => {
        services.getProperty({id:propertyid})
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
            <MyAccountPropertyLandlordInit props={{...props, theme:theme}} />
        </Container>
    )
})


const MyAccountPropertyTenant = withSubtheme((props) => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <PropertyActionStoreProvider>
                    <WrapComp {...props} />
                </PropertyActionStoreProvider>
            </MyaccountStoreProvider>
        </GlobalLayout>
    )
}, 'myPropertyPageLandlord', defaults)

export default React.memo(MyAccountPropertyTenant);
